import React, {useEffect} from "react";
import {Layout} from "../components/Layout";
import {useSelector} from "react-redux";
import {RootState} from "../store/reducers";
import {useActions} from "../store/hooks";
import {PrivacyPolicyPageModel} from "../store/models/privacy-policy-page";
import ReactMarkdown from "react-markdown";
import markdown from "../components/markdown";
import * as styles from "../styles/pages/privacy-policy.module.scss"
import SEO from "../components/SEO";
import StyledText, {TextStyles} from "../components/typography";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";

type PrivacyPolicyPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse
    }
}

const PrivacyPolicyPage = (props: PrivacyPolicyPageProps) => {
    const privacyPolicyModel: PrivacyPolicyPageModel = PrivacyPolicyPageModel.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);

    return (
        <>
            <SEO seo={privacyPolicyModel.seo}></SEO>
            <Layout menu={menuModel}>
                <StyledText variant={TextStyles.h1} className={styles.title}>
                    {privacyPolicyModel.title}
                </StyledText>
                <StyledText variant={TextStyles.bodyText} className={styles.subtitle}>
                    {privacyPolicyModel.updatedAtLabel}
                    {' '}
                    {privacyPolicyModel.updatedAt.formatted}
                </StyledText>

                <div className={styles.container}>
                    <ReactMarkdown
                        components={markdown}
                        skipHtml
                    >{privacyPolicyModel.privacyPolicy || ''}</ReactMarkdown>
                </div>
            </Layout>
        </>
    )
}

export default PrivacyPolicyPage;

export async function getServerData(context: GetServerDataProps): GetServerDataReturn  {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.privacyPolicyPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);

        return {
            props: {
                page,
                menu
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
