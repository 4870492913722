import Title from "./Title";
import Text from "./Text";
import Link from "./Link";
import {List} from "../news/body/components/List";

const markdown = {
    h1: Title,
    h2: Title,
    h3: Title,
    h4: Title,
    h5: Title,
    h6: Title,
    p: Text,
    a: Link,
    ol: List,
    ul: List,
}

export default markdown