import React from "react";
import * as styles from "../../styles/components/markdown/title.module.scss";
import StyledText, {TextStyles} from "../typography";

const Title = ({children, node, ...res}: any) => {
    return (
        <div className={styles.container}>
            <div className={styles.square}/>
            <StyledText tag={node.tagName || 'h4'} variant={TextStyles.bodyTextBold}>
                {children}
            </StyledText>
        </div>
    )
}

export default Title;
